const COUNTER_URL = "https://congreso-ceti-colomos.com/counter/vstr/";

export async function getCount() {
  try {
    const data = await fetch(COUNTER_URL).then((res) => res.json());
    return data;
  } catch (error) {
    return 0;
  }
}
