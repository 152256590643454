import React, { useState } from "react";
import "./invitation.css";
import iconInvite from "../../../assets/img/invite.svg";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const videos = ["QFdKcxr-ZfQ", "pkLFcjqVhIk"];

const hideRegistro = process.env.REACT_APP_HIDE_REGISTRO;

function InvitationSection() {
  const [currentId, setCurrentId] = useState(0);

  const Thumbnail = ({ idx, youtubeId }) => {
    return (
      <div
        className={`thumbnail mx-2 ${idx === currentId ? "selected" : ""}`}
        style={{
          backgroundImage: `url(https://img.youtube.com/vi/${youtubeId}/default.jpg)`,
        }}
        onClick={() => setCurrentId(idx)}
      ></div>
    );
  };
  return (
    <div id="invitation">
      <div className="container pb-5 py-md-5">
        <div className="row">
          <div className="col-lg me-md-4">
            <img src={iconInvite} alt="" className="d-inline icon" />
            <h2 className="d-inline">Invitación al congreso</h2>
            <div className="fade-separator d-none d-md-block" />
            <p className="py-4">
              Te invitamos a que participes al Quinto Congreso Virtual de
              Ingeniería, Ciencia y Tecnología del CETI Plantel Colomos:
              Conectando Mentes, Expandiendo Ideas.
            </p>
            {hideRegistro ? null : (
              <div className=" text-center text-lg-start">
                <Link to="/registro">
                  <Button size="lg" className="px-5 mb-4">
                    ¡Regístrate ya!
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <div className="col-lg d-flex flex-column align-items-center">
            <YouTube
              containerClassName="video-container"
              className="video"
              videoId={videos[currentId]}
              opts={{ playerVars: { autoplay: 0 } }}
            />
            <div className="d-flex flex-row justify-content-center mt-2">
              {videos.map((video, idx) => (
                <Thumbnail key={idx} idx={idx} youtubeId={video} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationSection;
