import React from "react";
import "./patrocinadores.css";
import iconSponsor from "../../../assets/img/sponsor.svg";
import imgCeti from "../../../assets/img/ceti.png";
import imgCengage from "../../../assets/img/cengage.jpg";
import imgLogo from "../../../assets/img/logo.jpg";

const sponsors = [
  { name: "Ceti Colomos", img: imgCeti },
  { name: "CENGAGE Learning", img: imgCengage },
  { name: "Segundo Congreso Virtual", img: imgLogo },
];

function PatrocinadoresSection() {
  return (
    <div id="patrocinadores">
      <div className="container position-relative pt-5 pb-3">
        <div className="row py-3">
          <div className="col text-center">
            <img src={iconSponsor} alt="" className="d-inline icon" />
            <h2 className="d-inline">Patrocinadores</h2>
          </div>
        </div>
        <div className="cards d-flex flex-column flex-md-row justify-content-around">
          {sponsors?.map((sponsor) => (
            <img
              className="sponsors mb-5 mb-md-0 px-4"
              title={sponsor.name}
              key={sponsor.name}
              src={sponsor.img}
              alt={sponsor.name}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PatrocinadoresSection;
