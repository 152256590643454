import React, { useEffect, useState } from "react";
import ponentes from "../../static/ponentes.json";
import conferences from "../../static/conferences.json";

const PonentesTable = ({ day, ponenteIds }) => {
  const [dayConferences, setDayConferences] = useState([]);

  useEffect(() => {
    const fetchConferences = () => {
      const filteredConferences = conferences.filter(
        (conference) => ponenteIds.includes(conference.speaker)
      );
      setDayConferences(filteredConferences);
    };
    fetchConferences();
  }, [day, ponenteIds]);

return (
    <div className="ponentes-table">
        <table className="table">
            <thead>
                <tr>
                    <th></th>
                    <th style={{ color: "blue" }}>Nombre del Ponente</th>
                    <th style={{ color: "blue" }}>Conferencia</th>
                    <th style={{ color: "blue" }}>Hora</th>
                </tr>
            </thead>
            <tbody>
                {dayConferences.map((conference) => {
                    const ponente = ponentes.find((p) => p.id === conference.speaker);
                    return (
                        <tr key={conference.id} style={{ backgroundColor: "orange" }}>
                            <td>
                                <img src={ponente?.img} alt={ponente?.name} style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                            </td>
                            <td>{ponente?.name}</td>
                            <td>{conference.title}</td>
                            <td>{conference.hour}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
);
};

export default PonentesTable;