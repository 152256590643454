import React from "react";
import "./hero.css";
import imgLogo from "../../../assets/img/logo.jpg";

function HeroSection() {
  return (
    <div id="hero" className="position-relative">
      <div className="content position-relative container">
        <div className="row h-100">
          <div className="col-md-9 d-flex flex-column justify-content-center left">
            <h1 className="hero-title">Quinto Congreso Virtual</h1>
            <h2 className="hero-subtitle">Ingeniería, Ciencia y Tecnología</h2>
            <p className="tagline mt-2">CETI Plantel Colomos</p>
            <p className="tagline">13 al 15 de Noviembre de 2024</p>
          </div>
          <div className="d-none d-md-flex col-md-3 flex-row justify-content-end">
            <div className="backdrop d-flex flex-column justify-content-center">
              <img src={imgLogo} alt="Logotipo del Quinto Congreso Virtual" className="hero-logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
