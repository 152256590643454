import React, { useEffect, useState } from "react";
import "./calendar.css";
import DayCard from "./DayCard";
import { getConferencesWithDays } from "../../services/conferencesService";
import PonentesTable from "./PonentesTable";

function CalendarPage() {
  const [conferences, setConferences] = useState({});

  useEffect(() => {
    const fetchConferences = async () => {
      const data = await getConferencesWithDays();
      setConferences(data);
    };
    fetchConferences();
  }, []);

  return (
    <div id="calendar">
      <div className="content-days">
      <div className="day-section">
          <div className="subtitle">
            <label>
              Miercoles <b>13</b> de Noviembre
            </label>
          </div>
          {conferences.dieciseis?.map((conference) => (
            <DayCard key={conference.id} conference={conference} />
          ))}
          <PonentesTable day="trece" ponenteIds={[1, 2, 3]} />
        </div>
        <div className="day-section">
          <div className="subtitle">
            <label>
              Jueves <b>14</b> de Noviembre
            </label>
          </div>
          {conferences.dieciseis?.map((conference) => (
            <DayCard key={conference.id} conference={conference} />
          ))}
          <PonentesTable day="catorce" ponenteIds={[4, 5, 6]} />
        </div>
        <div className="day-section">
          <div className="subtitle">
            <label>
              Viernes <b>15</b> de Noviembre
            </label>
          </div>
          {conferences.diecisiete?.map((conference) => (
            <DayCard key={conference.id} conference={conference} />
          ))}
          <PonentesTable day="Quince" ponenteIds={[7, 8, 9]} />
        </div>
      </div>
    </div>
  );
}

export default CalendarPage;